import React from "react";
import "./herosection.css";

const HeroSection = () => {
  return (
    <>
      <div className="container-fluid hero-main">
        <div className="container hero-section">
          <div className="row hero-content">
            <div className="col-md-6 content-left d-flex flex-column gap-5 justify-content-start align-content-center">
              <h1 className="hero-title">
                Internet & Intranet, Information, Innovation and Intelligence.
              </h1>
              <p className="hero-para">
                Join us as we venture into the world of technological wonder. We
                are architects of digital inventiveness, not just creators. Our
                passion is figuring out how to navigate the always changing
                world of technology and create solutions that go above and
                beyond.
              </p>
              <button type="button" className="hero-cta">
                Get Started
              </button>
            </div>
            <div className="col-md-6 content-right">
              <video loop autoPlay muted className="bg-video">
                <source
                  src={require("../../../assets/hero-video.mp4")}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
