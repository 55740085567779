import React from "react";
import "./blogsection.css";
import BlogImg from "../../../assets/blog-img.png";
import BlogImage1 from "../../../assets/wepapp-blog.webp";
import BlogImage2 from "../../../assets/tech-in-agriculture-blog.webp";
import BlogImage3 from "../../../assets/ai&iot-blog.webp";

const ServiceData = [
  {
    cardId: 0,
    blogLink: "/blog/1",
    cardImage: BlogImage1,
    cardtitle: "Web & App Development",
    cardpara:
      "We excel in app and web development, crafting seamless digital experiences that captivate users. Leveraging cutting-edge technologies, we create intuitive mobile apps and dynamic websites tailored to your needs.",
  },
  {
    cardId: 1,
    blogLink: "/blog/2",
    cardImage: BlogImage2,
    cardtitle: "Tech In Agriculture",
    cardpara:
      "Technology revolutionizes agriculture, boosting productivity and sustainability. From precision tools to AI analytics, innovations empower data-driven decisions, optimizing farming for a promising future.",
  },
  {
    cardId: 2,
    blogLink: "/blog/3",
    cardImage: BlogImage3,
    cardtitle: "AI and IOT Devices",
    cardpara:
      "AI and IoT revolutionize agriculture, optimizing productivity and resource use. Sensors collect data on soil and crops, analyzed by AI for informed decisions. This innovation fosters sustainability and resilience in farming.",
  },
];

const BlogSection = () => {
  return (
    <>
      <div className="container-fluid blog-main">
        <div className="container blog-section">
          <div className="row blog-content">
            <div className="col-md-6 blog-top-left d-flex flex-column gap-4 justify-content-start align-content-center">
              <h1 className="blog-title">Our Blog</h1>
              <p className="blog-para">
                Our blog section offers insightful articles covering a variety
                of topics relevant to technology, business, and innovation. Stay
                informed and inspired as we share valuable insights, tips, and
                trends to help you navigate the ever-evolving landscape of
                technology and business.
              </p>
            </div>
            <div className="col-md-6 blog-top-right">
              <img src={BlogImg} alt="blog section" />
            </div>
            <div className="row mt-2 gap-4 blog-card d-flex justify-content-center align-items-top">
              {ServiceData.map((items, index) => (
                <div
                  key={index}
                  href={items.blogLink}
                  className="card pb-2 col-md-4 mt-4"
                  style={{ height: "auto" }}
                >
                  <a href={items.blogLink}>
                    <img
                      src={items.cardImage}
                      className="card-img-top p-3 object-fit-cover"
                      alt={items.cardtitle}
                      style={{ height: "250px" }}
                    />
                    <div
                      className="card-body text-start"
                      style={{ height: "auto" }}
                    >
                      <h3 className="card-title fw-bold">{items.cardtitle}</h3>
                      <p className="card-text fw-light">{items.cardpara}</p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSection;
