import React from "react";
import "./servicesection.css";
import ServiceImg from "../../../assets/service-img.png";

const ServiceData = [
  {
    cardId: 0,
    cardtitle: "Consistent Research in Technical Sector",
    cardpara:
      "Regular research allows for timely adoption of emerging technologies, maximizing efficiency in agricultural processes and operations.",
  },
  {
    cardId: 1,
    cardtitle: "Mainstream Technologies in Agricultural Development",
    cardpara:
      "Implement AI algorithms for predictive analytics, crop monitoring, pest detection, and yield optimization, enhancing decision-making processes for farmers.",
  },
  {
    cardId: 2,
    cardtitle: "Facilitating Exchange of Information",
    cardpara:
      "Facilitate networking events, workshops, and conferences where researchers, students, entrepreneurs, and professionals can exchange insights, experiences, and innovative ideas.",
  },
  {
    cardId: 3,
    cardtitle: "AI & IOT",
    cardpara:
      "Conducting ongoing research in technical sectors like Artificial Intelligence (AI), Blockchain, and Internet of Things (IoT) ensures that agricultural development remains updated with the latest advancements.",
  },
  {
    cardId: 4,
    cardtitle: "Developing User-Friendly Technology",
    cardpara:
      "Create intuitive mobile applications or web-based platforms that provide actionable insights, guidance, and resources to empower farmers in decision-making and problem-solving.",
  },
  {
    cardId: 5,
    cardtitle: "Software Development",
    cardpara:
      "Software development is the systematic process of conceiving, specifying, designing, programming, documenting, testing, and bug fixing involved in creating and maintaining applications, frameworks, or other software components.",
  },
];

const ServiceSection = () => {
  return (
    <>
      <div className="container-fluid service-main">
        <div className="container service-section">
          <div className="row service-content">
            <div className="col-md-6 content-top-left d-flex flex-column gap-4 justify-content-start align-content-center">
              <h1 className="service-title">Our Services</h1>
              <p className="service-para">
                Our agriculture technology IT firm provides cutting-edge
                services for optimizing crop yields and resource management.
                From precision farming tech to data analytics tools, we empower
                farmers and agribusinesses to thrive sustainably.
              </p>
            </div>
            <div className="col-md-6 content-top-right">
              <img src={ServiceImg} alt="service section" />
            </div>
            <div className="row mt-2 service-card">
              {ServiceData.map((items, index) => (
                <div
                  key={index}
                  className="col-md-4 mt-4 card-list text-white text-start"
                >
                  <h3>{items.cardtitle}</h3>
                  <p>{items.cardpara}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceSection;
