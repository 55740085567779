import React from "react";
import HeroSection from "./HeroSection/HeroSection";
import EmpowerAgri from "./EmpowerAgri/EmpowerAgri";
import ServiceSection from "./ServiceSection/ServiceSection";
import PortfolioSection from "./PortfolioSection/PortfolioSection";
import PartnershipSection from "./PartnershipSection/PartnershipSection";
import BlogSection from "./BlogSection/BlogSection";
import ContactSection from "./ContactSection/ContactSection";

const Body = () => {
  return (
    <>
      <HeroSection />
      <EmpowerAgri />
      <ServiceSection />
      <PortfolioSection />
      <PartnershipSection />
      <BlogSection />
      <ContactSection />
    </>
  );
};

export default Body;
