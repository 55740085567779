import React from "react";
import "./aboutpage.css";
import ContactSection from "../Home/ContactSection/ContactSection";
import GoToTop from "../GoToTop";
import Image1 from "../../assets/image1-aboutpage.png";
import Image2 from "../../assets/image2-aboutpage.png";
import Image3 from "../../assets/image3-aboutpage.png";
import DirectorProfile from "./DirectorProfile";
import CompanyProfile from "./CompanyProfile";
import ManagementProfile from "./ManagementProfile";

const AboutPage = () => {
  return (
    <>
      <div className="container-fluid aboutpage-main">
        <div className="container aboutpage">
          <div className="row top-row">
            <div className="col-md-6 text-content text-start d-flex flex-column gap-5 justify-content-center align-items-center">
              <h1 className="aboutpage-title">
                Amplifying Strengths and Achieving Excellence
              </h1>
              <p className="aboutpage-para">
                Within our team, collaboration isn't just an idea—it's our
                driving energy. Together, we amplify our strengths, innovate
                tirelessly, and achieve extraordinary outcomes.
              </p>
            </div>
            <div className="col-md-6 about-img1">
              <img src={Image1} alt="muktinathitech" />
            </div>
          </div>
          <div className="row bottom-row">
            <div className="col-md-8 about-img2">
              <img src={Image2} alt="muktinathitech" />
            </div>
            <div className="col-md-4 about-img1">
              <img src={Image3} alt="muktinathitech" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mission-section">
        <div className="container mission-content">
          <div className="mission-header">
            <h1 className="mission-title">Our Mission</h1>
            <p className="mission-para">
              To empower the agricultural community with innovative IT solutions
              that enhance productivity, sustainability, and profitability while
              fostering environmental stewardship and resilience.
            </p>
          </div>
          <div className="mission-list mt-5">
            <div className="row d-flex  justify-content-center align-items-center">
              <div className="col-md-6 text-white mission-list-text">
                <p>
                  <b>Drive Efficiency:</b> <br />
                  Develop and deploy cutting-edge IT solutions to streamline
                  agricultural processes, optimize resource utilization, and
                  reduce operational costs for farmers and agribusinesses.
                </p>
              </div>
              <div className="col-md-6 text-white mission-list-text">
                <p>
                  <b>Foster Sustainability:</b> <br /> Promote sustainable
                  farming practices by integrating technology to minimize
                  environmental impact, conserve natural resources, and promote
                  biodiversity.
                </p>
              </div>
            </div>
            <div className="row mt-5 d-flex justify-content-center align-items-center">
              <div className="col-md-6 text-white mission-list-text">
                <p>
                  <b>Enhance Connectivity:</b> <br />
                  Bridge the digital divide in rural areas by expanding access
                  to technology and connectivity solutions, enabling farmers to
                  leverage digital tools and resources effectively.
                </p>
              </div>
              <div className="col-md-6 text-white mission-list-text">
                <p>
                  <b>Cultivate Partnerships:</b>
                  <br /> We strive to collaborate with industry, research, and
                  government partners to advance technology in agriculture
                  through teamwork and shared expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid intro-section">
        <div className="container intro-content">
          <div className="row">
            <div className="col-md-6 intro-img">
              <img src={Image1} alt="muktinathitech" />
            </div>
            <div className="col-md-6">
              <h1 className="intro-title">Muktinath itech Limited</h1>
              <p className="intro-para mt-5">
                At Muktinath Itech, our mission revolves around driving
                technological advancements within the agricultural sector. We
                are deeply committed to conducting ongoing research in
                cutting-edge technical domains such as Artificial Intelligence,
                Blockchain, and the Internet of Things (IoT) to propel
                agricultural development forward. Our initiatives prioritize
                relevance and efficiency, ensuring that our technological
                solutions have practical applications and real-world impact.
              </p>
              <p className="intro-para">
                Beyond exploration, Muktinath Itech is dedicated to the
                development of user-friendly solutions tailored to tackle the
                diverse challenges encountered in agriculture. Through our
                endeavors, we seek to empower stakeholders with accessible
                technology that not only facilitates problem-solving but also
                enhances productivity throughout the entire agricultural value
                chain.
              </p>
            </div>
          </div>
        </div>
      </div>
      <DirectorProfile />
      <ManagementProfile />
      <CompanyProfile />
      <ContactSection />
      <GoToTop />
    </>
  );
};

export default AboutPage;
