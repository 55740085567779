import React from "react";
import "./portfoliopage.css";
import ContactSection from "../Home/ContactSection/ContactSection";
import PortfolioPageImage1 from "../../assets/portfoliopage-image1.webp";
import PortfolioPageImage2 from "../../assets/portfoliopage-image2.webp";
import PortfolioPageImage3 from "../../assets/portfoliopage-image3.webp";
import PortfoliopageCard from "./PortfoliopageCard";
import GoToTop from "../GoToTop";

const PortfolioPage = () => {
  return (
    <>
      <div className="container-fluid portfoliopage-main">
        <div className="container portfoliopage">
          <div className="row  d-flex justify-content-center align-items-center">
            <div className="col-lg-8 text-content">
              <h1 className="portfoliopage-title">
                Spotlight on Success: Dive into Our Portfolio
              </h1>
              <p className="portfoliopage-para mt-4">
                Dive into our portfolio to witness our expertise in action. From
                web development to digital marketing, our projects showcase our
                commitment to delivering tailored solutions for success. Explore
                now and see how we can help your business thrive.
              </p>
            </div>
          </div>

          <div className="row image-content d-flex">
            <div className="col image-card1">
              <img src={PortfolioPageImage1} alt="green house automation" />
            </div>
            <div className="col image-card2">
              <img src={PortfolioPageImage2} alt="web development" />
            </div>
            <div className="col image-card3">
              <img src={PortfolioPageImage3} alt="tech in agriculture" />
            </div>
          </div>
        </div>
      </div>

      <PortfoliopageCard />
      <ContactSection />
      <GoToTop />
    </>
  );
};

export default PortfolioPage;
