import React from "react";
import "./directorprofile.css";
import PushpaImg from "../../assets/pushparaj-joshi.jpg";
// import BharatImg from "../../assets/bharatraj-dhakal.jpg";
// import TulsiramImg from "../../assets/tulsiram-dhakal.jpg";
// import GovindaImg from "../../assets/govindabahadur-raut.jpg";
// import SugarikaImg from "../../assets/sugarika-kc.jpg";

// const MemberData = [
//   {
//     memberId: 0,
//     image: BharatImg,
//     name: "Mr. Bharat Raj Dhakal",
//     designation: "Director",
//   },
//   {
//     memberId: 1,
//     image: TulsiramImg,
//     name: "Mr. Tulasi Ram Dhakal",
//     designation: "Director",
//   },
//   {
//     memberId: 2,
//     image: GovindaImg,
//     name: "Mr. Govinda Bahadhur Raut",
//     designation: "Director",
//   },
//   {
//     memberId: 3,
//     image: SugarikaImg,
//     name: "Mrs. Sugarika K.C.",
//     designation: "Director",
//   },
// ];

const CompanyProfile = () => {
  return (
    <>
      <div className="container-fluid mb-5 company-main">
        <div className="container director-profile pt-5 pb-5">
          <h1 className="director-profile-title">Muktinath iTech Team</h1>
          <div className="row mt-5 head-content">
            <div className="col-md-5 head-img">
              <img src={PushpaImg} alt="Pushpa Raj Joshi" />
            </div>
            <div className="col-md-6 text-start head-text">
              <h1 className="head-name">Mr.Pushpa Raj Joshi</h1>
              <p className="head-designation">Head of IT</p>
              <p className="head-intro">
              Mr. Pushpa Raj Joshi, a resident of Baneshwor, Kathmandu, currently serves as the IT Head at Muktinath Krishi Company Ltd. His academic journey led him to earn a Bachelor's degree in Electronics and Communication from Himalaya College of Engineering. Throughout his career, he has held diverse roles including Network Administrator, Broadcast Engineer, Senior IT Officer, and Deputy Chief Executive Officer in esteemed organizations. Mr. Joshi's professional trajectory is marked by continuous learning and skill enhancement. He has undergone several training programs to augment his expertise. His proficiency spans across various domains including Networking and Firewall Devices, Linux Operating System, Database management with MySql and SQL Server, Data Analysis, IoT-based Mobile and Web applications, Configuration of ISP-related equipment, RF Wireless Transmission, VMware, and High Availability (HA) modes. With a specialization in Electronics and Communication Engineering, Mr. Joshi brings a wealth of experience and knowledge to his current role, contributing significantly to the success and technological advancement of his organization.
              </p>
            </div>
          </div>
          {/* <div className="row member-content mt-5">
            {MemberData.map((items, index) => (
              <div key={items.memberId} className="col-lg-3 col-md-4 col-sm-6">
                <div className="member-img">
                  <img src={items.image} alt={items.name} />
                </div>
                <p className="member-name mt-4">{items.name}</p>
                <p className="member-designation">{items.designation}</p>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CompanyProfile;
