import React from "react";
import "./portfoliopagecard.css";
import DroneSprayer from "../../assets/ai&iot-blog.webp";
import Automation from "../../assets/greenhouse-automation.webp";

const CardData = [
  {
    cardId: 0,
    cardImage: Automation,
    title: "Green House Automation",
    para: "Our passion is figuring out how to navigate the always changing world of technology and create solutions that go above and beyond.",
  },
  {
    cardId: 1,
    cardImage: DroneSprayer,
    title: "Drone Sprayer",
    para: "Our passion is figuring out how to navigate the always changing world of technology and create solutions that go above and beyond.",
  },
];

const PortfoliopageCard = () => {
  return (
    <>
      <div className="container portfoliopage-card mb-5">
        <div className="row d-flex gap-5 justify-content-center align-items-top ">
          {CardData.map((items) => (
            <div
              key={items.cardId}
              className="col-3 p-0 pb-2 card"
              style={{ height: "auto" }}
            >
              <img
                src={items.cardImage}
                className="card-img-top object-fit-cover"
                alt={items.title}
                style={{ height: "250px" }}
              />
              <div className="card-body text-start" style={{ height: "auto" }}>
                <h5 className="card-title fw-bold">{items.title}</h5>
                <p className="card-text">{items.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PortfoliopageCard;
