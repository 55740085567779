import React from "react";
import "./ServicepageCard.css";
import CardImage1 from "../../assets/techresearch-services.webp";
import CardImage2 from "../../assets/mainstream-services.webp";
import CardImage3 from "../../assets/exchange-services.webp";
import CardImage4 from "../../assets/ai&iot-blog.webp";
import CardImage5 from "../../assets/ui-services.webp";
import CardImage6 from "../../assets/software-services.webp";

const CardData = [
  {
    cardId: 0,
    cardImage: CardImage1,
    title: "Consistent Research in Technical Sector",
    para: "Regular research allows for timely adoption of emerging technologies, maximizing efficiency in agricultural processes and operations.",
  },
  {
    cardId: 1,
    cardImage: CardImage2,
    title: "Mainstream Technologies in Agricultural Development",
    para: "Implement AI algorithms for predictive analytics, crop monitoring, pest detection, and yield optimization, enhancing decision-making processes for farmers.",
  },
  {
    cardId: 2,
    cardImage: CardImage3,
    title: "Facilitating Exchange of Information",
    para: "Facilitate networking events, workshops, and conferences where researchers, students, entrepreneurs, and professionals can exchange insights, experiences, and innovative ideas.",
  },
  {
    cardId: 3,
    cardImage: CardImage4,
    title: "AI & IOT",
    para: "Conducting ongoing research in technical sectors like Artificial Intelligence (AI), Blockchain, and Internet of Things (IoT) ensures that agricultural development remains updated with the latest advancements.",
  },
  {
    cardId: 4,
    cardImage: CardImage5,
    title: "Developing User-Friendly Technology",
    para: "Create intuitive mobile applications or web-based platforms that provide actionable insights, guidance, and resources to empower farmers in decision-making and problem-solving.",
  },
  {
    cardId: 5,
    cardImage: CardImage6,
    title: "Software Development",
    para: "Software development is the systematic process of conceiving, specifying, designing, programming, documenting, testing, and bug fixing involved in creating and maintaining applications, frameworks, or other software components.",
  },
];

const ServicepageCard = () => {
  return (
    <>
      <div className="container servicepage-card mb-5">
        <div className="row d-flex gap-5 justify-content-center align-items-top ">
          {CardData.map((items) => (
            <div
              key={items.cardId}
              class="col-3 p-0 pb-2 card"
              style={{ height: "auto" }}
            >
              <img
                src={items.cardImage}
                class="card-img-top object-fit-cover"
                alt={items.title}
                style={{ height: "250px" }}
              />
              <div class="card-body text-start" style={{ height: "auto" }}>
                <h5 class="card-title fw-bold">{items.title}</h5>
                <p class="card-text">{items.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServicepageCard;
