import React from "react";
import "./managementprofile.css";
import BharatImg from "../../assets/bharatraj-dhakal.jpg";
import RamsharanImg from "../../assets/ramsharan-timalsina.jpg";

const MemberData = [
  {
    memberId: 0,
    image: BharatImg,
    name: "Mr. Bharat Raj Dhakal",
    designation: "Managing Director",
  },
  {
    memberId: 1,
    image: RamsharanImg,
    name: "Mr. Ram Sharan Timalsina",
    designation: "Deputy General Manager & CS",
  },
];
const ManagementProfile = () => {
  return (
    <>
      <div className="container-fluid mb-5">
        <div className="container">
          <h1 className="management-profile-title">Management Team</h1>
          <div className="row management-content mt-5">
            {MemberData.map((items, index) => (
              <div key={items.memberId} className="col-md-4">
                <div className="management-img">
                  <img src={items.image} alt={items.name} />
                </div>
                <p className="management-name mt-4">{items.name}</p>
                <p className="management-designation">{items.designation}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementProfile;
