import React from "react";
import "./blogpage.css";
import { NavLink } from "react-router-dom";
import ContactSection from "../Home/ContactSection/ContactSection";
import BlogHeaderImage from "../../assets/blog-image.webp";
import BlogImage1 from "../../assets/wepapp-blog.webp";
import BlogImage2 from "../../assets/tech-in-agriculture-blog.webp";
import BlogImage3 from "../../assets/ai&iot-blog.webp";
import GoToTop from "../GoToTop";

const BlogpageData = [
  {
    cardId: 0,
    blogLink: "/blog/1",
    cardImage: BlogImage1,
    cardtitle: "Web & App Development",
    cardpara:
      "We excel in app and web development, crafting seamless digital experiences that captivate users. Leveraging cutting-edge technologies, we create intuitive mobile apps and dynamic websites tailored to your needs.",
  },
  {
    cardId: 1,
    blogLink: "/blog/2",
    cardImage: BlogImage2,
    cardtitle: "Tech in Agriculture",
    cardpara:
      "Technology revolutionizes agriculture, boosting productivity and sustainability. From precision tools to AI analytics, innovations empower data-driven decisions, optimizing farming for a promising future.",
  },
  {
    cardId: 2,
    blogLink: "/blog/3",
    cardImage: BlogImage3,
    cardtitle: "AI and IOT Devices",
    cardpara:
      "AI and IoT revolutionize agriculture, optimizing productivity and resource use. Sensors collect data on soil and crops, analyzed by AI for informed decisions. This innovation fosters sustainability and resilience in farming.",
  },
];

const BlogPage = () => {
  return (
    <>
      <div className="container-fluid blogpage-main">
        <div className="container blogpage">
          <div className="row  d-flex justify-content-center align-items-center">
            <div className="col-lg-8 text-content">
              <h1 className="blogpage-title">
                Cultivating Knowledge: Browse Our Blog Articles
              </h1>
              <p className="blogpage-para mt-4">
                Welcome to our blog—a hub of insights and inspiration. Dive in
                for expert tips, industry trends, and actionable advice. Explore
                now and level up your knowledge!
              </p>
            </div>
          </div>
          <div className="row image-content d-flex">
            <div className="col-md-12 p-0 blog-image-card1">
              <img src={BlogHeaderImage} alt="Itech blog" />
            </div>
          </div>
        </div>
      </div>
      <div className="container blogpage-cardsection mb-5">
        <div className="row mt-2 blogpage-card">
          {BlogpageData.map((items, index) => (
            <div key={index} className="col-md-4 mt-4 card-list text-start">
              <NavLink to={items.blogLink}>
                <div className="card">
                  <img
                    src={items.cardImage}
                    class="card-img-top p-3"
                    alt="hyper-local matching engine"
                  />
                  <div class="card-body">
                    <h3>{items.cardtitle}</h3>
                    <p class="card-para">{items.cardpara}</p>
                  </div>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
      <ContactSection />
      <GoToTop />
    </>
  );
};

export default BlogPage;
