import React, { useEffect } from "react";
import "./empoweragri.css";
import AOS from "aos";
import "aos/dist/aos.css";

const EmpowerAgri = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="container empoweragri" data-aos="slide-up">
          <div className="row empoweragri-content">
            <div className="col-md-6 empoweragri-left">
              <video loop autoPlay muted className="bg-video">
                <source
                  src={require("../../../assets/drone-video.mp4")}
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="col-md-6 empoweragri-right d-flex flex-column gap-5">
              <h1 className="empower-title">
                Empowering Agricultural Growth Through Tailored IT Solutions.
              </h1>
              <div className="row">
                <div className="col text-center">
                  <h2 className="empoweragri_experience-title  fw-bold">12+</h2>
                  <p>Project Completed</p>
                </div>
                <div className="col text-center">
                  <h2 className="empoweragri_experience-title fw-bold">10+</h2>
                  <p>Satisfied Customer</p>
                </div>
                <div className="col text-center">
                  <h2 className="empoweragri_experience-title fw-bold">07+</h2>
                  <p>Partners</p>
                </div>
              </div>
              <p className="empower-para">
                Our passion is figuring out how to navigate the always changing
                world of technology and create solutions that go above and
                beyond.
              </p>
              <a href="#learnmore" type="button" className="empower-cta">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpowerAgri;
