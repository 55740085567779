import React, { useEffect } from "react";
import "./partnershipsection.css";
import PartnershipImg from "../../../assets/partnershipimg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const PartnershipSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="container partnership-section">
          <div className="row partnership-content">
            <div className="col-4 partnership-left" data-aos="fade-right">
              <img src={PartnershipImg} alt="partnership" />
            </div>
            <div className="col-8 partnership-right d-flex flex-column gap-4">
              <h1 className="partnership-title">
                Empowering Agriculture through Technology Partnerships.
              </h1>
              <p className="partnership-para">
                The agriculture industry is undergoing a transformation fueled
                by technological advancements. As we strive to meet the growing
                demand for food sustainably, the role of technology in
                agriculture becomes increasingly vital.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnershipSection;
