import React, { useEffect } from "react";
import "./portfoliosection.css";
import AOS from "aos";
import "aos/dist/aos.css";

const PortfolioSection = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="container portfolio-section" data-aos="fade-up">
          <div className="row portfolio-content">
            <div className="col portfolio-top d-flex">
              <p className="fs-4 text-uppercase mb-0">What we have built?</p>
              <h1 className="portfolio-title">
                Use of information technology in development of Agriculture
                sector.
              </h1>
              <p className="portfolio-para">
                Our portfolio section highlights our successful projects,
                demonstrating our expertise in delivering tailored IT solutions
                across diverse industries. Explore how we've helped businesses
                like yours achieve their goals through innovative technology.
              </p>
            </div>
            <div
              className="row mt-5 portfolio-bottom d-flex"
              data-aos="fade-up"
            >
              <div className="col-md-6 portfolio-video">
                <video loop autoPlay muted className="bg-video">
                  <source
                    src={require("../../../assets/green-house.mp4")}
                    type="video/mp4"
                  />
                </video>
                <h3 className="mt-4">Green House Automation</h3>
              </div>
              <div className="col-md-6 portfolio-video">
                <video loop autoPlay muted className="bg-video">
                  <source
                    src={require("../../../assets/drone-video.mp4")}
                    type="video/mp4"
                  />
                </video>
                <h3 className="mt-4">Drone Sprayer</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioSection;
