import React, { useState } from "react";
import "./contactsection.css";
import emailjs from "@emailjs/browser";

const ContactSection = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    //Your EmailJS service ID, template ID and Public Key
    const serviceId = "service_1mttvkp";
    const templateId = "template_flgg01j";
    const publicKey = "gywGFHuS1zapb5wUb";

    //create a new object that contains dynamic template params
    const tempplateParams = {
      from_name: username,
      from_email: email,
      to_name: "Muktinath Krishi Company Limited",
      message: message,
    };

    //send the email using EmailJS
    emailjs
      .send(serviceId, templateId, tempplateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response);
        setUsername("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email", error);
      });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="contact-content">
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.344999792746!2d85.32223370948651!3d27.737502976065844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb194f10250f3d%3A0x53ec15dc96d7fe12!2sMuktinath%20Krishi%20Company!5e0!3m2!1sen!2snp!4v1710241232712!5m2!1sen!2snp"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Muktinath Krishi Company Limited"
              ></iframe>
            </div>
            <div className="row contact-container d-flex justify-content-center align-content-center">
              <div className="col-10 p-0 contact-info-content">
                <div className="row">
                  <div className="col-md-6 message-side">
                    <form onSubmit={handleSubmit}>
                      <h3>Send Message</h3>
                      <div className="row g-2 mt-4">
                        <div class="col-md form-floating">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInputGroup1"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <label for="floatingInputGroup1">Username</label>
                        </div>
                        <div class="col-md form-floating mb-3">
                          <input
                            type="email"
                            class="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label for="floatingInput">Email address</label>
                        </div>
                      </div>

                      <div class="form-floating">
                        <textarea
                          class="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea2"
                          style={{ height: "100px" }}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        <label for="floatingTextarea2">Your Message</label>
                      </div>
                      <div className="message-cta mt-4 float-end">
                        <button type="submit">Send Message</button>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6 info-side">
                    <h3>Contact Information</h3>
                    <div className="info-side-content mt-4 d-flex flex-column">
                      <p>
                        <i class="bi bi-geo-alt-fill me-4"></i>Basundhara,
                        Kathmandu
                      </p>
                      <p>
                        <i class="bi bi-telephone-fill me-4"></i>+977-01-4950097
                      </p>
                      <p>
                        <i class="bi bi-envelope-fill me-4"></i>
                        info@muktinathitech.com.np
                      </p>
                    </div>

                    <div className="contact_social-link mt-5">
                      <ul class="list-unstyled p-0 d-flex">
                        <li class="ms-0">
                          <a class="link-body-emphasis" href="https://www.instagram.com/muktinathkrishicompany/">
                            <i
                              class="bi bi-instagram text-white"
                              width="24"
                              height="24"
                            ></i>
                          </a>
                        </li>
                        <li class="ms-3">
                          <a class="link-body-emphasis" href="https://www.facebook.com/muktinathkrishiCo.Ltd/">
                            <i
                              class="bi bi-facebook text-white"
                              width="24"
                              height="24"
                            ></i>
                          </a>
                        </li>
                        
                        <li class="ms-3">
                          <a class="link-body-emphasis" href="https://www.linkedin.com/company/muktinath-krishi-company-ltd/mycompany/">
                            <i
                              class="bi bi-linkedin text-white"
                              width="24"
                              height="24"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
